import React from 'react';
import { MDBCol, } from "mdb-react-ui-kit";
import { SettingsUl } from '../setting.style';
import {
    TextureQualityWrapper,
    TextureList,
    textureAuto,
    texture480p,
    texture720p,
    texture2160p
} from './texture.style';

function TextureQuality({ popSettingsMobile, menuProps }) {
    const { showTexture } = menuProps
    const [textureList, setTextureList] = React.useState(textureAuto)

    const changeTexture = (texture) => {
        if (texture === 'Auto • 480p') {
            setTextureList(textureAuto)
        } else if (texture === '480p') {
            setTextureList(texture480p)
        } else if (texture === '720p') {
            setTextureList(texture720p)
        } else if (texture === '2160p') {
            setTextureList(texture2160p)
        }
    }
    return (
        <TextureQualityWrapper show={showTexture}>
            <SettingsUl>
                <MDBCol className='col-8' style={{ cursor: 'pointer' }}>
                    <div className="py-2" onClick={popSettingsMobile}>
                        <b className="bi bi-chevron-left fw-bold"></b>
                        &ensp;&ensp;
                        <span className=""> Texture quality</span>
                    </div>
                </MDBCol>
                <MDBCol className='vw-100 col-12 border-bottom border-white'></MDBCol>
                <MDBCol className='col-12'>
                    <TextureList textureListData={textureList} action={changeTexture} />
                </MDBCol>
            </SettingsUl>
        </TextureQualityWrapper>
    )
}

export default TextureQuality
