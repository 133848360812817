import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

function Alerts({ errors, msg }) {
    React.useEffect(() => {
        if (errors && errors !== {}) { toast.error(`Error: ${JSON.stringify(errors)}`); }
        if (msg && msg !== {}) { toast.error(`Warning: ${JSON.stringify(msg)}`); }
    }, [errors, msg]);
    return <></>;
}

const mapStateToProps = (state) => ({
    errors: state.alert.errors,
    msg: state.alert.msg,
});

export default connect(mapStateToProps, null)(Alerts);
