import styled from 'styled-components';

export const LoaderPageBg = styled.div`
  position: fixed;
  z-index: ${({ setIndex }) => setIndex ? 199 : 0};
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #AB6DFA 0%, #6803e3f5 86.61%);
  display: ${({ display }) => display === 'block' ? 'block' : 'none'};
  justify-content: center;
  align-items: center;
    section{
      position: relative;
      top:50%; 
    }
`;


export const InstructionOverlay = styled.div`
  position: fixed;
    z-index: ${({ setIndex }) => setIndex ? 199 : 0};
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #121212eb;
    display: ${({ display }) => display === 'block' ? 'block' : 'none'};
    justify-content: center;
    align-items: center;
`;

export const FrameLoaderOverlay = styled.div`
  position: fixed;
  z-index: 100;
  top:50%;
  left: 0;
  width: 100%;
  height: 0;
  display: ${({ display }) => display === 'block' ? 'block' : 'none'};
  justify-content: center;
  align-items: center;
    section{
      position: relative;
      top:0; 
    };
    @media (max-width: 992px) {
      width: 100%;
    };
    @media screen and (max-width: 768px) { 
      left: auto;
    
    }
`;

// export const DivSection = styled.section``
export const DivSection = styled.section.attrs(props => ({
  className: `d-lg-block d-none`
}))`
`;

export const LazyLoaderWrapper = styled.div`
  position: fixed;
  z-index: ${({ display }) => display === 'block' ? 900 : 0};
  top:0;
  bottom:0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fffffffa;
  display: ${({ display }) => display === 'block' ? 'block' : 'none'};
  justify-content: center;
  align-items: center;
`;
