import React from 'react'

function ImageLoader({ src, cls, width, height, spinnerSize,styles }) {
    const [loading, setLoading] = React.useState(true);
    // const counter = React.useRef(0);
    const imageLoaded = () => {
        setLoading(false);
        // counter.current += 1;
        // if (counter.current >= src.length) {
        //     setLoading(false);
        // }
    }
    return (
        <>
            <div style={{ display: loading ? "" : "none" }}>
                <small className="spinner-border text-white"
                    style={{
                        width: spinnerSize, height: spinnerSize, ...styles
                    }}></small>
            </div>
            <div style={{ display: loading ? "none" : "" }}>
                <img src={src} alt="" className={cls} onLoad={imageLoaded} style={{ width: width, height: height }} />
            </div>
        </>
    )
}

export default ImageLoader
