import styled from 'styled-components';
// import ImageLoader from '../pageloaders/ImageLoader';
// import { MDBListGroup, MDBListGroupItem, MDBBadge } from "mdb-react-ui-kit";

export const SettingsWrapper = styled.div.attrs(props => ({
    className: ''
}))`
border-top-right-radius: 2% !important;
border-top-left-radius: 2% !important;
overflow-y: hidden;
width: 100%;
position: absolute;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
color: white;
transition-property: all;
transition: all .5s ease-in-out;
transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
transform-origin: left;
padding-top: 20px;

z-index: ${({ show }) => show ? '200' : '0'}; 
left: ${({ show }) => show ? '0' : '1000vh'}; 
top: 0px; 

@media only screen and (max-width: 960px) {
    position: fixed !important;
    transform-origin: top !important;
    bottom:0 !important;
    left: 0 !important;
    top: ${({ show }) => show ? '73%' : '1000vh'} !important; 
};

@media only screen and (max-width: 700px) {
    top: ${({ show }) => show ? '60%' : '1000vh'} !important; 
  };
`;

export const SettingsUl = styled.ul.attrs(props => ({
    className: 'row'
}))`
list-style: none;
width: 100%;
li{
    padding: 5px 0px;
}
`;

