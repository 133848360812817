import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ViewerMode from "../pages/StandardViewer/ViewerMode";
import StreamMode from "../pages/StandardViewer/StreamMode";
import CheckChrome from '../components/Alerts/CheckChrome';


function Routes() {
    const [isChrome, setIsChrome] = React.useState(true)
    React.useEffect(() => {
        var isIOSChrome = window.navigator.userAgent.match('CriOS')
        var android = window.navigator.userAgent.match(/Android/i)
        var iphone = window.navigator.userAgent.match(/iPhone/i)
        setIsChrome(() => window.chrome && !isIOSChrome && !android && !iphone)
    }, [setIsChrome])
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/viewer" component={isChrome ? ViewerMode : CheckChrome} />
                {/* <Route exact path="/stream" component={isChrome ? StreamMode : CheckChrome} /> */}
                {/* <Route exact path="/stream/:uniqueId" component={isChrome ? StreamMode : CheckChrome} /> */}
                <Route exact path="/stream" component={StreamMode} />
                <Route exact path="/stream/:uniqueId" component={StreamMode} />
                <Redirect from="/" to="/viewer?archive_id=hiroki1" />
                <Route component={isChrome ? ViewerMode : CheckChrome} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
