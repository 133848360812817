import React from 'react';
import { MobileDetailWrapper } from './detailSection.style';
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { CreatorSection, DetailPost } from './DesktopDetailSection';
// import Drawer from "react-bottom-drawer";

function MobileDetailSection({
    menuProps,
    popDetailsMobile
}) {
    const { showDetails } = menuProps;
    const [flipUp, setFlipUp] = React.useState(false)

    return (
        <>
            <MobileDetailWrapper show={showDetails} flipUp={flipUp}>
                <MDBRow className='border-bottom border-white'>
                    <div className="col-12 text-center floating-updown pt-2" onClick={() => setFlipUp(!flipUp)}>
                        {flipUp ?
                            <b className="bi bi-chevron-double-down fw-bolder text-center text-white"></b>
                            :
                            <b className="bi bi-chevron-double-up fw-bolder text-center text-white"></b>
                        }
                    </div>
                    <MDBCol className='col-6 text-start'>
                        <span className="h4">Holotch - Hologram Live Streaming Demo</span>
                    </MDBCol>
                    <MDBCol className='col-6 text-end'>
                        <span className="h4" onClick={popDetailsMobile}>
                            <i className="bi bi-x text-white fs-1"></i>
                        </span>
                    </MDBCol>
                </MDBRow>


                <MDBRow className='py-2'>
                    <MDBCol className='col-8'>
                        <span className="">Handsome bald man in suit</span> <br />
                        <span className="text-muted">200 000 views • 12/09/2021</span>
                    </MDBCol>
                </MDBRow>

                <MDBRow className=''>
                    <MDBCol className='col-11 mx-auto border-top'></MDBCol>
                    <CreatorSection />
                    <DetailPost />
                </MDBRow>
            </MobileDetailWrapper>
        </>
    )
}

export default MobileDetailSection;
