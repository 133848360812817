import React from 'react';
import { MDBCol, } from "mdb-react-ui-kit";
import { SettingsUl } from '../setting.style'
import { MainSettingsWrapper, MainMenuList, OtherMenuList } from './mainsettings.style';
// import ImageLoader from '../../pageloaders/ImageLoader';



function MainSettings({
    popSettingsMobile,
    popMenu,
    menuProps,
}) {
    const { showSettings } = menuProps;

    React.useEffect(() => {
        let xbg = document.getElementsByClassName('sc-bdvvtL')[0];
        if (showSettings && xbg) {
            // xbg.style.position = 'absolute';
            // document.body.style.background = 'red';
        } else {
            // xbg.style.position = 'unset';
            // document.body.style.background = 'unset';

        }
    }, [showSettings]);

    return (
        <MainSettingsWrapper show={showSettings}>
            <section className="">
                <SettingsUl>
                    <MDBCol className=''>
                        <MainMenuList action={popMenu} />

                        <OtherMenuList
                            cls={'d-lg-flex d-none'}
                            action={popMenu}
                            title={'View in VR headset'}
                            icon={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/Custom/view_in_vr_headset_black_24dp.png'}
                        />
                        <OtherMenuList
                            cls={'d-lg-flex d-none border-top border-white pt-1'}
                            action={popMenu}
                            title={'Capture tool'}
                            icon={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/photo_camera_black_24dp+1.png'}
                        />
                        <OtherMenuList
                            cls={'d-lg-flex d-none'}
                            action={popMenu}
                            title={'Help'}
                            icon={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/help_outline_black_24dp+1.png'}
                        />
                    </MDBCol>
                    <li className="row d-lg-none d-flex border-top border-white my-2">
                        <div className="col-2 col-md-1">
                            <div className="m-1">
                                <i className="bi bi-x-lg h6 text-white"></i>
                            </div>
                        </div>
                        <div className="col-10 col-md-4" onClick={popSettingsMobile}>
                            <div className="m-1 m-md-0 my-md-1 text-start">
                                Cancel
                            </div>
                        </div>
                    </li>
                </SettingsUl>
            </section>

        </MainSettingsWrapper>
    )
}

export default MainSettings;
