import styled from 'styled-components';
// import ImageLoader from '../../pageloaders/ImageLoader';
import { SettingsWrapper } from '../setting.style';

/* Texture Quality Section */

export const HelpSectionWrapper = styled(SettingsWrapper)`
top: ${({ show }) => show ? '0' : '1000vh'} !important;  
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212 !important;  
section{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212 !important;
}
`

