import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from './reduxStore';
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.min.css";
import Alerts from './components/Alerts';
// eslint-disable-next-line
// import PageLoader from './components/pageloaders/mainpageloader';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './styles/index.css'
import Routes from './routes';
import reportWebVitals from './reportWebVitals';


function Holotch() {
    React.useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
    });

    return (
        <Provider store={store}>
            <Alerts />
            <ToastContainer />
            {/* <PageLoader /> */}
            <Routes />
        </Provider>
    )
}

ReactDOM.render(
    <React.Fragment>
        <Holotch />
    </React.Fragment>,
    document.getElementById('root') || document.getElementById('holotch') || document.createElement('div')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();