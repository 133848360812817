import React from 'react';
import styled from 'styled-components';
// import ImageLoader from '../../components/pageloaders/ImageLoader';
// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

export const MobileDetailWrapper = styled.div.attrs(props => ({
    className: 'container-fluid'
}))`
border-top-right-radius: 2% !important;
border-top-left-radius: 2% !important;
overflow-y: hidden;
position: fixed;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
color: white;
z-index: ${({ show }) => show ? '200' : '0'}; 

bottom:0; 
top: ${({ show, flipUp }) => show && flipUp ? '0' : show && !flipUp ? '820px' : '1000vh'}; 
@media only screen and (max-width: 700px) {
    top: ${({ show, flipUp }) => show && flipUp ? '0' : show && !flipUp ? '400px' : '1000px'}; 
  }
transition-property: all;
transition: all .5s ease-in-out;
transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
transform-origin: top;
`;

export const UserInitialzComp = styled.p.attrs(props => ({
    className: 'text-center rounded-circle'
}))`
  background: ${({ bgcolor }) => bgcolor};
  color: white;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 47px;
  width: 50px;
  // padding: 10px;
  `;

export const UserInitialz = ({ initial, bgcolor }) => {
    return (
        <UserInitialzComp bgcolor={bgcolor}>
            <span>{initial}</span>
        </UserInitialzComp>
    )
}

export const DetailBody = styled.div.attrs(props => ({
    className: 'text-start'
}))`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.87);
  `




export const MainSettingsSection = styled.ul.attrs(props => ({
    className: 'row'
}))`
list-style: none;
width: 100%;
`