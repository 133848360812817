import styled from 'styled-components';
// import ImageLoader from '../../pageloaders/ImageLoader';
import { SettingsWrapper } from '../setting.style';

/* Texture Quality Section */

export const BackgroundSetsWrapper = styled(SettingsWrapper)`
`

export const backgroundListData = [
    {
        bg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Backgrounds/Grass.png',
        name: 'Grass',
        selected: false
    },
    {
        bg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Backgrounds/Beach.png',
        name: 'Beach',
        selected: false
    },
    {
        bg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Backgrounds/Sand.png',
        name: 'Sunset beach',
        selected: false
    },
]