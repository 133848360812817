import React, { useState } from 'react';
import {
  // MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  // MDBModalHeader,
  // MDBModalTitle,
  MDBModalBody,
  // MDBModalFooter,
} from 'mdb-react-ui-kit';
// import { RoundedIconBtn } from '../../pages/hologramUi/hologramui.style';
// import ImageLoader from '../pageloaders/ImageLoader';
function ShareModal({ Luncher }) {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [copied, setCopied] = React.useState(false);

  const copyLink = (id) => {
    // e.preventDefault();
    const copyAction = document.getElementById('copy-link');
    // const getLink = document.getElementById(id);
    // var text = getLink.innerText;
    copyAction.style.color = "#C399F8";
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => {
      copyAction.style.color = "#FFFFFF";
      setCopied(false);
    }, 2000);
  }
  // React.useEffect(() => {
  //     let modal_backdrop = document.querySelector('.modal-backdrop');
  //     if (basicModal && modal_backdrop)
  //         modal_backdrop.style.zIndex = '0';
  //     // {
  //     //     modal_backdrop.style.zIndex = '0';
  //     // }else{
  //     //     modal_backdrop.style.zIndex = 'unset';
  //     // }
  // },[basicModal])
  return (
    <div>
      <>
        {/* <RoundedIconBtn icon='fa fa-share text-white text-end' action={toggleShow} /> */}
        <div className="">
          <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons/Custom/Share%403x.png"
            alt=""
            className=""
            style={{ width: '35px', height: '35px', marginRight: '-10px', background: 'rgba(18, 18, 18, 0.5)', borderRadius: '50%', cursor: 'pointer' }}
            onClick={toggleShow}
          />
        </div>
        <MDBModal show={basicModal} tabIndex='-1'>
          <MDBModalDialog className='rounded' style={{ background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212' }}>
            <MDBModalContent>
              <MDBModalBody className='p-0 m-0'>
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="col-12 p-3">
                      <div className="row">
                        <p className="text-start col-6">Share</p>
                        <p className="text-end col-6"> <i className='fa fa-times clickable' onClick={toggleShow} /></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center pb-5">
                    <div className="border col-9 text-center mx-auto rounded my-3 px-0">
                      <span id="copy-link"
                        contentEditable={true}
                        suppressContentEditableWarning={true}>
                        {window.location.href.slice(0, window.location.href.indexOf('?'))}...
                      </span>
                      &ensp;
                      &ensp;
                      &ensp;
                      <span className="text-holotch clickable" onClick={() => copyLink('copy-action')} id='copy-action'>
                        {copied ? <>COPIED <i className="bi bi-check"></i></> : 'COPY LINK'}
                      </span>
                    </div>

                  </div>


                </div>
              </MDBModalBody>


            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>

    </div>
  )
}

export default ShareModal