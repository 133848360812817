import styled from 'styled-components';

export const SharingWrapper = styled.div.attrs(props => ({
    className: 'container  d-lg-none d-block'
}))`
position: fixed;
z-index: 100;
bottom: 25%;
@media (min-width: 600px) {
    left: 60px
}
`
export const SharingRow = styled.div.attrs(props => ({
    className: 'row'
}))`

`
export const SharingList = styled.ul.attrs(props => ({
    className: 'col-3 ms-auto'
}))`
list-style: none;
`
export const SharingItem = styled.li.attrs(props => ({
    className: 'text-center'
}))`
cursor: pointer;
margin-bottom: 8px;
i{
    font-size: 35px;
    text-shadow: 1px 2px black;
}
img{
    text-shadow: 2px 2px #FFFFFF;
}

`