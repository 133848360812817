import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { InstructionOverlay } from './pageloader.style';
import ImageLoader from './ImageLoader';

const instructionData = [
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/touch_app_black_24dp+1.png',
        instruction: "Tap to play/pause the animation"
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/pinch_black_24dp+1.png',
        instruction: "Pinch to zoom"
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/Custom/drag_app_black_24dp+2%402x.png',
        instruction: "Drag to rotate"
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/Custom/2finger_pan_black_24dp+2%402x.png',
        instruction: "Two-fingers drag to pan"
    },
];

export const InstructionList = ({ help }) => {
    const infoStyle = {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 18,
    }
    return (
        <>
            {instructionData.map((info, i) => (
                <MDBRow key={i} className='my-3 text-start' data-aos={help ? '' : `fade-up`}
                    data-aos-delay={`${i + 1}00`}
                    data-aos-duration={`${i + 1}00`}
                >
                    <MDBCol center className='col-3'>
                        <div className="m-1 ps-4">
                            <ImageLoader src={info.icon} cls='img-fluid' width={35} height={35} />
                        </div>
                    </MDBCol>
                    <MDBCol center className='col-9'>
                        <div className="m-1">
                            <span style={infoStyle}>{info.instruction}</span>
                        </div>
                    </MDBCol>
                </MDBRow>
            ))}
        </>
    )
}

function InstructionPage() {
    const [timeControl, setTimeControl] = React.useState(0)
    const [instructionDisplayStatus, setInstructionDisplayStatus] = React.useState('block')
    const [skipInstructions, setSkipInstructions] = React.useState(false)



    // Display Control
    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimeControl((t) => t - 1);
        }, 1000);
        return () => clearInterval(interval);

    }, [])

    const skipInstruction = () => {
        setInstructionDisplayStatus('none')
    }
    React.useEffect(() => {
        if (timeControl < 1) {
            setSkipInstructions(true)
        }
    }, [timeControl])

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol center className='col-12 mx-auto text-center row text-white'>
                    <InstructionOverlay
                        display={instructionDisplayStatus}
                        setIndex={instructionDisplayStatus === 'block' ? true : false}
                    >
                        {/* <p className="col-3 ms-auto mt-3" onClick={skipInstruction}>
                            <span className="text-white">Skip <i className="bi bi-chevron-right"></i></span>
                        </p> */}

                        <div className='mx-2 mt-5 pt-5'>
                            <InstructionList />
                        </div>

                        {!skipInstructions ?
                            <p className="mt-5 mb-5 small">Skip in {timeControl} seconds ...</p>
                            :
                            <button onClick={skipInstruction}
                                className="btn btn-outline-white btn-rounded mt-5">
                                Skip <i className="bi bi-chevron-right"></i>
                            </button>
                        }
                    </InstructionOverlay>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default InstructionPage;
