import React from 'react';
import MainSettings from '../../settingsSection/MainSettings';
// import DesktopDetailSection from '../../components/detailSection/DesktopDetailSection';
import MobileDetailSection from '../../detailSection/MobileDetailSection';
import TextureQuality from '../../settingsSection/TextureQuality';
import BackgroundSets from '../../settingsSection/BackgroundSets';
import HelpSection from '../../settingsSection/HelpSection';
import { DesktopGesturesWrapper } from '../../../pages/StandardViewer/hologramui.style';
import recorder from 'react-canvas-recorder';
import { toast } from "react-toastify";
import FileSaver from 'file-saver';
import { triggerBase64Download } from 'react-base64-downloader';
import watermark from 'watermarkjs';
// import HolotchViewer from '../../components/holotchViewer/VRViewer';

export const playStatus = {
    PLAYING: 0,
    PAUSED: 1,
    BUFFERING: 2,
    FINISHED: 3,
}

export const PlayerGestures = ({ popMenu, menuProps, popDetailsMobile, popSettingsMobile }) => {
    return (
        <>
            <MobileDetailSection
                popMenu={popMenu}
                menuProps={menuProps}
                popDetailsMobile={popDetailsMobile}
            />
            <MainSettings
                menuProps={menuProps}
                popSettingsMobile={popSettingsMobile}
                popMenu={popMenu}
            />
            <TextureQuality
                popSettingsMobile={popSettingsMobile}
                popMenu={popMenu}
                menuProps={menuProps}
            />
            <BackgroundSets
                popSettingsMobile={popSettingsMobile}
                popMenu={popMenu}
                menuProps={menuProps}
            />
            <HelpSection
                popSettingsMobile={popSettingsMobile}
                popMenu={popMenu}
                menuProps={menuProps}
            />
        </>
    )
}


export const PlayerGesturesDesktop = ({ popMenu, menuProps, popDetailsMobile, popSettingsMobile }) => {
    return (
        <div className='d-lg-block d-none container'>
            <div className="ms-auto col-md-6">
                <DesktopGesturesWrapper className="">
                    {/* <PlayerGestures
                    popMenu={popMenu}
                    menuProps={menuProps}
                    popDetailsMobile={popDetailsMobile}
                /> */}
                    <MainSettings
                        popSettingsMobile={popSettingsMobile}
                        popMenu={popMenu}
                        menuProps={menuProps}
                    />

                    <TextureQuality
                        menuProps={menuProps}
                        popSettingsMobile={popSettingsMobile}
                    />

                    <BackgroundSets
                        menuProps={menuProps}
                        popSettingsMobile={popSettingsMobile}
                    />

                    <HelpSection
                        menuProps={menuProps}
                        popSettingsMobile={popSettingsMobile}
                    />
                </DesktopGesturesWrapper>
            </div>
        </div>
    )
}

export const SudoComp = () => {
    return (
        <div className="vh-100" style={{ background: 'url(https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Grass.png) no-repeat center', backgroundSize: '100%' }}>
            <div className="position-absolute bottom-0 pb-5 align-item-center text-center mx-auto">
                <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Hologram.png" alt="" className="img-fluid" />
            </div>
        </div>
    )
}

export const startStreaming = (cameraStream, setCameraStream) => {
    stopStreaming();
    var mediaSupport = 'mediaDevices' in navigator;
    if (mediaSupport && null == cameraStream) {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(function (mediaStream) {
                setCameraStream(mediaStream);
                var stream = document.querySelector("video");
                stream.srcObject = mediaStream;
                stream.play();
            })
            .catch(function (err) {
                console.log("Unable to access your camera");
                console.log("Unable to access camera: " + err);
            });
    }
    else {
        setCameraStream(null);
        console.log('Camera is already running');
        return;
    }
}


export const captureSnapshot = (cameraStream, setCameraStream, setCapturedImage) => {
    setCameraStream(null);
    if (null != cameraStream) {
        var stream = document.querySelector("video");
        var capture = document.getElementById("canvas");
        var ctx = capture.getContext('2d');
        var img = new Image();
        if (stream) {
            stream.style.display = "block";
            ctx.drawImage(stream, 0, 0, capture.width, capture.height);
            img.src = capture.toDataURL("image/png");
            setCapturedImage(img.src)
        }

    } else {
        console.log(cameraStream)
    }
    stopStreaming();
}

export const stopStreaming = (cameraStream, setCameraStream) => {
    if (null != cameraStream) {
        var track = cameraStream.getTracks()[0];
        var stream = document.querySelector("video");
        if (stream) {
            // console.log(stream)
            track.stop();
            stream.load();
            // stream.style.display = "none";
            setCameraStream(null);
        }

    }
}


export const stopRecording = () => {
    // Do something with the file
    toast.dark('saving your video...', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    recorder.stop();
    const file = recorder.save();
    recorder.save()
    return FileSaver.saveAs(file, 'holotch media')
}

export const startRecording = (ref, setRecoredActive, recoredActive, setVideoRecTimer, setRecTimerSec) => {
    setRecoredActive(!recoredActive)
    if (recoredActive) {
        stopRecording();
        setVideoRecTimer('00:00');
        setRecTimerSec('none');
        clearInterval();
    } else {
        recorder.createStream(ref.current);
        recorder.start();
        setRecTimerSec('')
        let count = 0;
        setInterval(() => {
            count++;
            secondsToMinutes(count, setVideoRecTimer);
        }, 1000);
    }
}


export const secondsToMinutes = (time, setVideoRecTimer) => {
    let min = Math.floor(time / 60) < 2 ? '0' + Math.floor(time / 60) : Math.floor(time / 60)
    let sec = Math.floor(time % 60) < 10 ? '0' + Math.floor(time % 60) : Math.floor(time % 60)
    setVideoRecTimer(`${min}:${sec}`);
}


export const capturePhoto = (setCapturedPhoto, setCameraCapture) => {
    var capture = document.querySelector("canvas");
    if (capture) {
        setCapturedPhoto(capture.toDataURL("image/png"))
        setCameraCapture(true)
    }
    const logoxs = 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo-small.png';
    // apply watermark on thumbnail
    var options = {
        init(img) {
            img.crossOrigin = 'anonymous'; // VERY IMPORTANT!!!
        },
        type: 'image/jpeg',
    };
    // watermark ① text center
    watermark([capture.toDataURL("image/png"), logoxs], options)
        .image(watermark.image.upperRight(0.5))
        .then(function (img_step1) {
            setCapturedPhoto(img_step1.src)
            // console.log(img_step1.src);
            // triggerBase64Download(img_step1.src, 'holotch media');
        });
}

export const savePhoto = (capturedPhoto) => {
    triggerBase64Download(capturedPhoto, 'holotch media');
    toast.dark('saving your photo...', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    // setInterval(() => {
    //     setCameraCapture(false)
    // }, 4000);
}


export const handleShare = (data) => {
    if (navigator.share) {
        navigator
            .share({
                title: "Holotch",
                text: data,
                url: "web.holotch.com",
            })
            .then(() => {
                console.log("Successfully shared");
            })
            .catch((error) => {
                console.error("Something went wrong", error);
            });
    } else {
        toast.dark('this action is not supported by your browser', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    // setCameraCapture(false)
};