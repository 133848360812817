import React from 'react';
// import { Link } from 'react-router-dom';
// import ImageLoader from '../../../components/pageloaders/ImageLoader';
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { TopScreenOverlay } from '../hologramui.style';
import ShareModal from '../../../components/modal/shareModal';



function SectionOne({ arView, iosViewer, videoRecTimer, recTimerSec, iosExpand }) {

    return (
        <div style={{ display: iosExpand ? 'block' : 'none' }}>
            <TopScreenOverlay>
                <div className='mt-lg-4 mt-1 container-fluid'>
                    <div className="col-lg-11">
                        <MDBRow>
                            <MDBCol className='ms-auto text-end text-white d-none d-lg-block'>
                                <ShareModal />
                            </MDBCol>
                            {/* <MDBCol className='col-4 col-lg-2  text-start'>
                                <p style={{ color: 'rgb(171, 109, 250)' }} className='small' id="catch_gestures"></p>
                                {!iosViewer ?
                                    <Link to='/'>
                                        {!arView ?
                                            <RoundedIconBtn icon='bi bi-x text-white fs-5' />
                                            :
                                            <RoundedIconBtn icon='bi bi-x text-white fs-5' />
                                        }
                                    </Link>
                                    : <></>}
                            </MDBCol>
                            <MDBCol className='col-4 col-lg-8 text-center mt-2'>
                                <p className="my-auto text-danger border border-danger small"
                                    style={{ display: recTimerSec }}
                                >{videoRecTimer}</p>
                            </MDBCol> */}



                            {/* <MDBCol className='col-4 ms-auto text-end text-white vw-100'> */}

                            {/* <div className="row"> */}
                            {/* <div className="col-6 col-lg-3">
                                    <RoundedIconBtn className='d-lg-none d-block' icon='d-lg-none d-block bi bi-info-circle text-white fs-5 fw-bolder' action={popDetailsMobile} />
                                    {!iosViewer ?
                                        <RoundedBtn className='d-none d-lg-block'>

                                            <div className="px-1 py-2 d-lg-block d-none"
                                            >
                                                {!arView ?
                                                    <img src={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/view_in_ar_white_24dp+1%402x.png'}
                                                        alt="" style={{ width: '15px', height: '15px' }} />
                                                    : <></>}
                                            </div>

                                        </RoundedBtn> : <></>}
                                </div> */}
                            {/* <div className="col-9"></div> */}
                            {/* <div className="d-none d-lg-block text-end ms-auto col-4"> */}
                            {/* <RoundedIconBtn icon='bi bi-gear text-white fs-5' action={popSettingsMobile} /> */}
                            {/* <RoundedIconBtn icon='bi bi-info text-white fs-5' action={popSettingsMobile} /> */}
                            {/* <RoundedIconBtn icon='fa fa-share text-white fs-5' ction={popSettingsMobile} /> */}


                            {/* </div> */}
                            {/* </div> */}

                            {/* </MDBCol> */}
                        </MDBRow>
                    </div>
                </div>
            </TopScreenOverlay>
        </div>
    )
}

export default SectionOne;
