import React from 'react';
import styled from 'styled-components';
// import ImageLoader from '../../components/pageloaders/ImageLoader';
// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";


export const PlayerTimeLineOverlay = styled.div`
position: fixed;
z-index: 100;
// top:88%;
top:${({ iosViewer }) => iosViewer ? 85 : 88}%;
width: 100%;
color: #FFFF;
justify-content: center;
align-items: center;
margin-top: 7px;
  section{
    position: relative;
    top:50%; 
  };
  @media (max-width: 992px) {
    width: 100% !important;
    // top:90%;
    top:${({ iosViewer }) => iosViewer ? 87 : 90}%;
  }
`;

export const PlayerInput = styled.input`
-webkit-appearance: none;
height: 3.5px;
border-radius: 50px;
background: rgba(255, 255, 255, 0.418); ;  
background-image: linear-gradient(90deg,white,white 17%,white 34%,white 51%,white 68%,white 85%,white)!important;
background-size: ${({ value, max }) => (value / max) * 100}%;
background-repeat: no-repeat;
animation: progress-animation 2s linear infinite;
padding: 0;
width: 100%;
cursor:pointer;
@media (max-width: 992px) {
    height: 3px;
  }
`;

export const PlayerTimer = styled.p`
color: #FFFF;
font-size: 12px !important;
margin: 0;
`;

const PlayerControlIconWrapper = styled.span.attrs(props => ({
  className: props.className
}))`
color: #FFFF;
font-size: 20px;
`;

export const PlayerControlIcon = ({ chooseIcon, status, playStatus, restartPlayback, togglePlayPause, setStatus }) => {
  return (
    <PlayerControlIconWrapper
      onClick={status === playStatus.FINISHED ? () => restartPlayback() : () => togglePlayPause(status, playStatus, setStatus)}
    >
      {chooseIcon(status)}
    </PlayerControlIconWrapper>
  )
}