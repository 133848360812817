import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { BackgroundSetsWrapper, backgroundListData } from './backgroundset.style';
import ImageLoader from '../../pageloaders/ImageLoader';


function BackgroundSets({ popSettingsMobile, menuProps }) {
    const { showBackground } = menuProps
    return (
        <BackgroundSetsWrapper show={showBackground}>
            <MDBContainer>
                <MDBRow className="">
                    <MDBCol className="">
                        <div className="py-2" onClick={popSettingsMobile} style={{ cursor: 'pointer' }}>
                            <b className="bi bi-chevron-left fw-bold"></b>
                            &ensp;&ensp;
                            <span className=""> Background</span>
                        </div>
                    </MDBCol>
                    <MDBCol className='vw-100 col-12 border-bottom border-white mb-2'></MDBCol>
                    <MDBCol className="col-12">
                        <MDBRow className="">
                            {backgroundListData.map((bg, i) => (
                                <MDBCol className="col-4" key={i}>
                                    <div className="">
                                        <ImageLoader src={bg.bg} height={130} width='100%' cls='img-fluid rounded' />
                                        <figcaption style={{ fontSize: 10 }}>{bg.name}</figcaption>
                                    </div>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </BackgroundSetsWrapper>
    )
}

export default BackgroundSets;
