import * as React from 'react';
import styled from 'styled-components';
import ImageLoader from '../../pageloaders/ImageLoader';
import { SettingsWrapper } from '../setting.style';
import { Link } from 'react-router-dom';
// import { MDBListGroup, MDBListGroupItem, MDBBadge } from "mdb-react-ui-kit";

export const MainSettingsWrapper = styled(SettingsWrapper)`
`;


const mainMenuListData = [
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/texture_black_24dp+1%402x.png',
        title: 'Texture quality',
        subtitle: 'Auto (480p)',
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/autofps_select_black_24dp+1.png',
        title: 'Framerate',
        subtitle: 'Auto (30 fps)',
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/wallpaper_black_24dp+1.png',
        title: 'Background',
        subtitle: 'Grass',
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/view_in_ar_white_24dp+1.png',
        title: 'View in AR',
        subtitle: '',
    },
]

export const MainMenuList = ({ action }) => {

    return (
        <>
            {mainMenuListData.map((lst, i) => (
                <li className="row" key={i} onClick={() => action(lst.title)}
                    style={{ cursor: "pointer" }}>
                    <div className="col-2 col-md-1 col-lg-2">
                        <div className="m-1">
                            <ImageLoader src={lst.icon} width={20} height={20} />
                        </div>
                    </div>
                    <div className="col-10 col-md-4 col-lg-8">
                        <div className="m-1 m-md-0 my-md-1 text-start">
                            <small>{lst.title}
                                {lst.subtitle !== '' ? <>
                                    <i className="bi bi-dot text-white"></i>
                                    <Link to={''} className="text-muted" onClick={(e) => e.preventDefault()}>{lst.subtitle}</Link>
                                </> : ""}
                            </small>
                        </div>
                    </div>
                </li>
            ))
            }
        </>
    )
}

export const OtherMenuList = ({ action, title, subtitle, icon, cls }) => {
    const handleChange = (e) => { }
    return (
        <>
            <li className={`row ${cls}`} onClick={() => action(title)}
                style={{ cursor: "pointer" }}>
                <div className="col-2 col-md-1 col-lg-2">
                    <div className="m-1">
                        <ImageLoader
                            src={icon}
                            width={20} height={20} />
                    </div>
                </div>
                <div className="col-10 col-md-4 col-lg-8">
                    <div className="m-1 m-md-0 my-md-1 text-start">
                        <small>
                            <span className="text-white" onClick={(e) => e.preventDefault()}>{title}</span>
                            {subtitle ? <>
                                <i className="bi bi-dot text-white"></i>
                                <Link to={''} className="text-muted" onClick={(e) => e.preventDefault()}>{subtitle}</Link>
                            </> : ""}
                        </small>
                    </div>
                </div>
                {title === 'Capture tool' ?
                    <div className="col-lg-2">
                        <div className="m-1 m-md-0 my-md-1 text-start">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox" id="flexSwitchCheckChecked"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    : <></>}
            </li>
        </>
    )
}