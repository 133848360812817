import * as React from 'react';
import styled from 'styled-components';
// import ImageLoader from '../../pageloaders/ImageLoader';
import { SettingsWrapper } from '../setting.style';

/* Texture Quality Section */

export const TextureQualityWrapper = styled(SettingsWrapper)`
`
export const textureAuto = [
    {
        check: true,
        option: 'Auto • 480p'
    },
    {
        check: false,
        option: '480p'
    },
    {
        check: false,
        option: '720p'
    },
    {
        check: false,
        option: '2160p'
    },
]
export const texture480p = [
    {
        check: false,
        option: 'Auto • 480p'
    },
    {
        check: true,
        option: '480p'
    },
    {
        check: false,
        option: '720p'
    },
    {
        check: false,
        option: '2160p'
    },
]
export const texture720p = [
    {
        check: false,
        option: 'Auto • 480p'
    },
    {
        check: false,
        option: '480p'
    },
    {
        check: true,
        option: '720p'
    },
    {
        check: false,
        option: '2160p'
    },
]
export const texture2160p = [
    {
        check: false,
        option: 'Auto • 480p'
    },
    {
        check: false,
        option: '480p'
    },
    {
        check: false,
        option: '720p'
    },
    {
        check: true,
        option: '2160p'
    },
]
const ActiveTexture = styled.li`
background: rgba(255, 255, 255, 0.12);
`
export const TextureList = ({ action, textureListData }) => {
    return (
        <>
            {textureListData.map((textures, i) => (
                <div key={i}>
                    {textures.check ?
                        <ActiveTexture className="my-2 p-1" key={i} onClick={() => action(textures.option)}>
                            {textures.check ?
                                <i className="bi bi-check2"></i>
                                :
                                <i className="">&ensp;&ensp;</i>}
                            &ensp; <span className="" style={{ cursor: 'pointer' }}> {textures.option}</span>

                        </ActiveTexture>
                        :
                        <li className="my-2 p-1" key={i} onClick={() => action(textures.option)} style={{ cursor: 'pointer' }}>
                            {textures.check ?
                                <i className="bi bi-check2"></i>
                                :
                                <i className="">&ensp;&ensp;</i>}
                            &ensp; <span className=""> {textures.option}</span>

                        </li>
                    }
                </div>
            ))}
        </>
    )
}