import React from 'react';
import MobileCover from './MobileCover';
import DesktopCover from './DesktopCover';
import './checkchrome.css'

function CheckChrome() {
  const [copied, setCopied] = React.useState(false);

  const copyLink = (id) => {
    // e.preventDefault();
    const copyAction = document.getElementById('copy-link');
    // const getLink = document.getElementById(id);
    copyAction.style.color = "#C399F8";
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => {
      copyAction.style.color = "#FFFFFF";
      setCopied(false);
    }, 2000);
  }

  React.useEffect(() => {
    let checkchrome_main = document.getElementById('checkchrome_main');
    if (checkchrome_main) {
      document.body.style.backgroundColor = 'black';
    }

  }, [])

  return (
    <div className='container' id='checkchrome_main'>
      <div className='row'>
        <div className='d-none d-md-block'>
          <DesktopCover
            copied={copied}
            setCopied={setCopied}
            copyLink={copyLink}
          />
        </div>
        <div className='d-block d-md-none'>
          <MobileCover
            copied={copied}
            setCopied={setCopied}
            copyLink={copyLink} />
        </div>
      </div>
    </div>
  )
}

export default CheckChrome;