import React from 'react';
import { LoaderPageBg, DivSection } from './pageloader.style';
import { MDBContainer, MDBRow, MDBCol, MDBProgress, MDBProgressBar } from 'mdb-react-ui-kit';
import BarLoader from 'react-bar-loader'
import ImageLoader from './ImageLoader';

const instructionData = [
    // {
    //     icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/touch_app_black_24dp+1.png',
    //     instruction: "Tap to play/pause the animation"
    // },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/pinch_black_24dp+1@2x.png',
        instruction: "Pinch to zoom"
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/Custom/drag_app_black_24dp+2%402x.png',
        instruction: "Drag to rotate"
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/Custom/2finger_pan_black_24dp+2%402x.png',
        instruction: "Two-fingers drag to pan"
    },
];
const infoStyle = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18,
}
function MainPageLoader({ checkLoader, archivePreLoader, mainLoader, viewerLoader, setStartStreaming }) {
    const [loaderTimer, setLoaderTimer] = React.useState(0)
    const [displayStatus, seDisplayStatus] = React.useState('block')


    // Progress Bar Logic
    React.useEffect(() => {
        var loader = 0
        var interval = setInterval(function () {
            setLoaderTimer(() => loader += 1)
            if (loader === 101) {
                clearInterval(interval);
            }
        }, 100);
    }, [])

    React.useEffect(() => {
        if ((document.readyState === 'complete' && loaderTimer > 99) || checkLoader === true) {
            seDisplayStatus('none')
            setLoaderTimer(100)
            setStartStreaming(true)

        }
    }, [
        loaderTimer,
        displayStatus,
        checkLoader,
        setStartStreaming
    ])

    return (
        <MDBContainer className="">
            <MDBRow>
                <MDBCol center className='col-4 mx-auto text-center'>
                    {displayStatus === 'block' && viewerLoader ?
                        <>
                            <LoaderPageBg display={displayStatus} setIndex={displayStatus === 'block' ? true : false}>

                                <div className="d-lg-none d-block">
                                    <div className="my-lg-5 my-3">
                                        <MDBCol size='12' center data-aos="fade-right" className='mt-5'>
                                            <ImageLoader
                                                src='https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo.png'
                                                cls='img-fluid'
                                                width={'50%'}
                                                height={'50%'}
                                            />
                                        </MDBCol>
                                    </div>
                                    <div
                                        style={{ border: "solid 1px white", background: '', color: 'white', margin: "5px 10px 0 10px", borderRadius: "10px" }}
                                    >
                                        {instructionData.map((info, i) => (
                                            <MDBRow key={i} className='my-lg-3 my-2 text-start' data-aos={'fade-up'}
                                                data-aos-delay={`${i + 1}00`}
                                                data-aos-duration={`${i + 1}00`}
                                            >
                                                <MDBCol center className='col-4 text-end'>
                                                    <div className="m-1 ps-lg-4 ps-0">
                                                        <ImageLoader src={info.icon} cls='img-fluid' width={35} height={35} />
                                                    </div>
                                                </MDBCol>
                                                <MDBCol center className='col-8 text-start'>
                                                    <div className="m-1">
                                                        <span style={infoStyle}>{info.instruction}</span>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        ))}
                                    </div>
                                    <div style={{ marginTop: '15%' }}>
                                        <div className="mt-1 mt-md-3 mx-3 col-8 mx-auto text-center">
                                            {archivePreLoader ?
                                                <>
                                                    <div className="text-center text-white">
                                                        <BarLoader color="#AB6DFA" height="4" style={{ borderRadius: 50 }} />
                                                        <p className='mt-2'>
                                                            <span className="small"> Loading background please wait...</span>
                                                        </p>
                                                    </div>
                                                </>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>


                                <DivSection>
                                    <MDBCol size='12' center data-aos="fade-right">
                                        <ImageLoader
                                            src='https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo.png'
                                            cls='img-fluid'
                                        />
                                    </MDBCol>
                                    <div className='col-8 col-lg-2 col-md-4 mx-auto text-center mt-4' data-aos="fade-left">
                                        <>
                                            {loaderTimer < 99 && mainLoader ?
                                                <>
                                                    <MDBProgress>
                                                        <MDBProgressBar className='progress-bar-loader-fill' bgColor='#AB6DFA'
                                                            width={loaderTimer} valuemin={0} valuemax={100} style={{ borderRadius: 20 }}>
                                                        </MDBProgressBar>
                                                    </MDBProgress>
                                                    <p className='mt-2'>
                                                        <span className="small text-white"> Holotch TechStart...</span>
                                                    </p>

                                                </>
                                                :
                                                <>
                                                    <>
                                                        {/* <div className="text-center text-white">
                                                                <BarLoader color="#AB6DFA" height="4" style={{ borderRadius: 50 }} />
                                                                <p className='mt-2'>
                                                                    <em className="small"> Loading Archives please wait...</em>
                                                                </p>
                                                            </div> */}
                                                    </>

                                                </>
                                            }
                                        </>
                                        {archivePreLoader ?
                                            <>
                                                <div className="text-center text-white">
                                                    <BarLoader color="#AB6DFA" height="4" style={{ borderRadius: 50 }} />
                                                    <p className='mt-2'>
                                                        <span className="small"> Loading archives please wait...</span>
                                                    </p>
                                                </div>
                                            </>
                                            : <></>
                                        }
                                    </div>
                                </DivSection>


                            </LoaderPageBg>
                        </>
                        :
                        <>
                            {/* <div className="d-md-none d-block">
                                <InstructionPage />
                            </div> */}
                        </>
                    }
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default MainPageLoader;
