import { handleMethodsErrors } from "../../reduxStore/config";

//T Y P E S
const PHOTO_MODE = 'PHOTO_MODE';
const VIDEO_MODE = 'VIDEO_MODE';
// const VIDEO_RECORDING_ACTIVE = 'VIDEO_RECORDING_ACTIVE';
const TEXTURE_QUALITY = 'TEXTURE_QUALITY';
const BACKGROUND_LIST = 'BACKGROUND_LIST';
const FRAMERATE_LIST = 'FRAMERATE_LIST';
const MOBILE_SETTINGS = 'MOBILE_SETTINGS';
const MOBILE_DETAILS = 'MOBILE_DETAILS';
const AR_SETTINGS = 'AR_SETTINGS';
const HELP_SETTINGS = 'HELP_SETTINGS';
const OFF_POPED_SETTINGS = 'OFF_POPED_SETTINGS';


//A C T I O N S

export const switchViewerMode = (mode) => async (dispatch) => {
    if (mode === 'photo_mode') {
        try {
            return dispatch({ type: PHOTO_MODE });
        } catch (error) {
            return dispatch(handleMethodsErrors(error));
        }
    } else if (mode === 'video_mode') {
        try {
            return dispatch({ type: VIDEO_MODE });
        } catch (error) {
            return dispatch(handleMethodsErrors(error));
        }
    }

};

export const popMenu = (option) => async (dispatch) => {
    console.log(option)
    if (option !== null) {
        if (option === 'Texture quality') {
            try {
                return dispatch({ type: TEXTURE_QUALITY });
            } catch (error) {
                return dispatch(handleMethodsErrors(error));
            }
        }
        else if (option === 'Background') {
            try {
                popSettingsMobile()
                return dispatch({ type: BACKGROUND_LIST });
            } catch (error) {
                return dispatch(handleMethodsErrors(error));
            }
        }
        else if (option === 'Framerate') {
            try {
                popSettingsMobile()
                // return dispatch({ type: FRAMERATE_LIST });
                return dispatch(popSettingsMobile());
            } catch (error) {
                return dispatch(handleMethodsErrors(error));
            }
        }
        else if (option === 'View in AR') {
            try {
                popSettingsMobile()
                // return dispatch({ type: AR_SETTINGS });
                return dispatch(popSettingsMobile());
            } catch (error) {
                return dispatch(handleMethodsErrors(error));
            }
        }
        else if (option === 'Help') {
            try {
                return dispatch({ type: HELP_SETTINGS });
            } catch (error) {
                return dispatch(handleMethodsErrors(error));
            }
        }

    } else {
        try {
            return dispatch({ type: OFF_POPED_SETTINGS });
        } catch (error) {
            return dispatch(handleMethodsErrors(error));
        }
    }

};

export const popSettingsMobile = () => async (dispatch) => {
    try {
        return dispatch({ type: MOBILE_SETTINGS });
    } catch (error) {
        return dispatch(handleMethodsErrors(error));
    }
}

export const popDetailsMobile = () => async (dispatch) => {
    try {
        return dispatch({ type: MOBILE_DETAILS });
    } catch (error) {
        return dispatch(handleMethodsErrors(error));
    }
}




//R E D U C E R
const initialState = {
    screenMargin: { mx: 'x', md: 3 },
    photoMode: false,
    videoMode: true,
    captureBtn: {
        bg: 'white',
        bd: 'dark'
    },
};

const menuState = {
    showTexture: false,
    showSettings: false,
    showDetails: false,
    showBackground: false,
    showHelp: false,
};

export function hologramUIReducer(state = initialState, action) {
    switch (action.type) {
        case PHOTO_MODE:
            return {
                ...state,
                screenMargin: { mx: 'x', md: 3 },
                photoMode: false,
                videoMode: true,
                captureBtn: {
                    bg: 'white',
                    bd: 'dark'
                },
            };
        case VIDEO_MODE:
            return {
                ...state,
                screenMargin: {
                    mx: 's',
                    md: 7
                },
                photoMode: true,
                videoMode: false,
                captureBtn: {
                    bg: 'danger',
                    bd: 'white'
                },
            };
        default:
            return state;
    }
}

export function popMenuReducer(state = menuState, action) {
    switch (action.type) {
        case TEXTURE_QUALITY:
            return {
                ...state,
                showTexture: true,
                showSettings: false,
                showDetails: false,
                showBackground: false,
                showHelp: false,
            };
        case BACKGROUND_LIST:
            return {
                ...state,
                showTexture: false,
                showSettings: false,
                showDetails: false,
                showBackground: true,
                showHelp: false,
            };
        case FRAMERATE_LIST:
            return {
                ...state,
                // showTexture: false,
                // showDetails: false,
                // showHelp: false,
                // showBackground: false,
            };
        case MOBILE_SETTINGS:
            return {
                ...state,
                showSettings: !(state.showSettings),
                showDetails: false,

                showTexture: false,
                showHelp: false,
                showBackground: false,
            };
        case MOBILE_DETAILS:
            return {
                ...state,
                showSettings: false,
                showDetails: !(state.showDetails),

                showTexture: false,
                showHelp: false,
                showBackground: false,
            };
        case AR_SETTINGS:
            return {
                ...state,
            };
        case HELP_SETTINGS:
            return {
                ...state,
                showTexture: false,
                showSettings: false,
                showDetails: false,
                showBackground: false,
                showHelp: true,
            };
        case OFF_POPED_SETTINGS:
            return {
                ...state,
                showTexture: false,
                showDetails: false,
                showHelp: false,
                showBackground: false,
            };
        default:
            return state;
    }
}