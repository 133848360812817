import { toast } from "react-toastify";
import axios from "axios";
const BASE_URL = ``;

// Request Handler
export const request = axios.create({
    baseURL: BASE_URL,
});

// Request Header
export const headers = () => {
    const token = localStorage.getItem("holoth_token");
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
};


// Handel Methods Erros
export const handleMethodsErrors = (error) => {
    toast.dark(JSON.stringify(error), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}