import React from 'react';
import { HelpSectionWrapper } from './helpsection.style';
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { InstructionList } from '../../pageloaders/instructionpage';
import ImageLoader from '../../pageloaders/ImageLoader';

function HelpSection({ popSettingsMobile, menuProps }) {
    const { showHelp } = menuProps;
    
    const openTab = (e, cityName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        e.currentTarget.className += " active";
    }

    return (
        <HelpSectionWrapper show={showHelp}>
            <MDBContainer className="">
                <section className="row pt-2">
                    <MDBCol className="col-2">
                        <div className="" onClick={popSettingsMobile}>
                            <b className="bi bi-x fw-bold fs-1"></b>
                        </div>
                    </MDBCol>
                    <MDBCol className="col-10">
                        <div className="tab row">
                            <div className="col-6">
                                <div className="p-0 m-0">
                                    <button className="text-white small tablinks active" onClick={(e) => openTab(e, 'gestures')}>GESTURES</button>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="p-0 m-0">
                                    <button className="text-white small tablinks" onClick={(e) => openTab(e, 'about')}>ABOUT</button>
                                </div>
                            </div>

                        </div>
                    </MDBCol>
                </section>

                <MDBRow className='row mt-4'>
                    <MDBCol className='vw-100 col-12'></MDBCol>
                    <MDBCol className='col-12'>
                        <div id="gestures" className="tabcontent" style={{ display: 'block' }}>
                            <InstructionList help={true} />
                        </div>
                        <div id="about" className="tabcontent row">
                            <div className="col-6">
                                <ImageLoader
                                    src='https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo.png'
                                    cls='img-fluid'
                                />
                            </div>
                            <div className="col-12">
                                <p className="my-4 text-justify">
                                    Hologram is the new digital media for 5G era
                                    and mainstream for AR/VR. We develop its core
                                    technology and create the new way of communication
                                    and entertainment, including capture, storage,
                                    and delivery of holograms to all.
                                </p>
                                <a className=""
                                    style={{ color: '#C399F8' }}
                                    href={'mailto:hiroki@holotch.com'}>
                                    hiroki@holotch.com
                                </a>
                                <p className="">
                                    <small className="text-muted">Location</small>
                                    <br />
                                    <span className="">Tokyo, Japan</span>
                                </p>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

        </HelpSectionWrapper>
    )
}

export default HelpSection;
