import React from 'react';
import ImageLoader from '../pageloaders/ImageLoader';
import { SharingWrapper, SharingRow, SharingList, SharingItem } from './SharingContent.style';


function SharingContent({ handleShare, getLike, setGetLike, iosViewer }) {
    return (
        <SharingWrapper >
            <SharingRow>
                <SharingList>
                    {!iosViewer ?
                        <>
                            <SharingItem>
                                <ImageLoader
                                    cls={'rounded-circle img-fluid mb-3'}
                                    width={40}
                                    height={40}
                                    spinnerSize={15}
                                    src={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png'}
                                />
                            </SharingItem>
                            <SharingItem>
                                <span className="" onClick={() => setGetLike(!getLike)}>
                                    <i className={`bi bi-heart-fill text-${getLike ? 'getlike' : 'white'}`}></i> <br />
                                    <small className="text-white" style={{ position: 'relative', top: -15 }}>{getLike ? '261k' : '260k'}</small>
                                </span>
                            </SharingItem>
                            <SharingItem>
                                <i className="bi bi-chat-left-dots-fill text-white"></i> <br />
                                <small className="text-white" style={{ position: 'relative', top: -15 }}>258</small>
                            </SharingItem>
                            <SharingItem>
                                <span className="" onClick={() => handleShare('www.holotch.com')}>
                                    <i className="fa fa-share text-white" aria-hidden="true"></i> <br />
                                    <small className="text-white" style={{ position: 'relative', top: -10 }}>3830</small>
                                </span>
                            </SharingItem>
                        </>
                        :
                        <></>
                    }
                </SharingList>
            </SharingRow>
        </SharingWrapper >
    )
}

export default SharingContent;
