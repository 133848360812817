
export const togglePlayPause = (status, playStatus, setStatus) => {
    switch (status) {
        case playStatus.PLAYING:
            setStatus(playStatus.PAUSED)
            break;
        case playStatus.PAUSED:
            setStatus(playStatus.PLAYING)
            break;
        default:
            break;
    }
}

export const restartPlayback = (playStatus, setTimelinePos, setStatus) => {
    setTimelinePos(0);
    setStatus(playStatus.PLAYING);
}

export const setMetadata = (length, rate, setHologramLength, setFramerate) => {
    setHologramLength(length);
    setFramerate(rate);
}
export const incrementTimelinePos = (timelinePos, setTimelinePos) => {
    setTimelinePos(parseInt(timelinePos) + 1);
}

export const updateStatus = (newStatus, setStatus) => {
    setStatus(newStatus)
}

export const forward2Secs = (timelinePos, framerate, playStatus, setTimelinePos, setStatus) => {
    var twoSecsAhead = timelinePos - (2 * framerate);
    var newPos = twoSecsAhead < 0 ? 0 : twoSecsAhead;
    setTimelinePos(newPos);
    setStatus(playStatus.BUFFERING);
}

export const changeTimeLinePos = (e, setTimelinePos) => {
    setTimelinePos(e.target.value);
}

export const pageLoaderCheck = (loader, setCheckLoader) => {
    setCheckLoader(loader ? false : true)
}




export const liveStreamTimerFunc = (setLiveStreamTimer) => {
    setTimeout(function liveStreamTimerF() {
        var m = 0;
        var s = 0;
        var h = 0;
        let getSec
        let getMin
        let getHour
        var z = '';
        z = (s < 10) ? '0' : '';
        getSec = z + s;
        s++;
        if (s === 60) {
            s = 0;
            z = (m < 10) ? '0' : '';
            getMin = z + m;
            m++;
            if (m === 60) {
                m = 0;
                z = (h < 10) ? '0' : '';
                getHour = z + h;
                h++;
            }
        }
        // setTimeout(liveStreamTimerF, 1000);
        setLiveStreamTimer({
            sec: getSec,
            min: getMin,
            hour: getHour,
        });
    }, 1000)
}

/*
 END - HOLOGRAM SECTION LOGIC HANDLERS
 */