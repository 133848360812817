import React from 'react';


function MobileCover() {
  return (
    <div className="centralize text-center col-12 text-white">
      <img src="" alt="" className="" />
      <h1 className="fs-5 roboto-text"> Mobile browsers are not supported yet.</h1>
      <p className="">Please open this page with Google Chrome on your desktop browser.</p>
      {/* <div className="my-3">
        {isMobile ?
          <button className='btn btn-white chrome-check' onClick={() => handleShare(window.location.href)}>
            open with chrome
          </button>
          : ""}
      </div> */}
      {/* <p className="mt-3 text-holotch">
        <a rel="noreferrer" target="_blank"
          href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=en&gl=US"
          className="text-holotch">GET GOOGLE CHROME</a>
      </p> */}
    </div>
  )
}

export default MobileCover;