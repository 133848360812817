import React from 'react';
import { playStatus } from '../hologram/hologramUi.helpers';
import * as THREE from 'three';
import * as B64 from 'base64-arraybuffer';
import * as AWS from "aws-sdk";

AWS.config.update({
    region: 'eu-west-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "eu-west-1:6e78104c-077b-4763-ba31-8a9c174800bd"
    })
});
var dynamodb = new AWS.DynamoDB.DocumentClient({ region: 'ap-northeast-1' })
// var s3 = new AWS.S3();
// eslint-disable-next-line
var s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    s3BucketEndpoint: true,
    endpoint: "d33cam3goooz1c.cloudfront.net" //cloudfront endpoint
})
var BATCH_SIZE = 2
// var BUFFER_SIZE = 2
// var archive_id = 'aeb2bd40-77b8-11ec-8c9c-9ba4ebee5585'

function getGeometry(encodedGeom) {
    var decoder = new TextDecoder("utf-8");
    var view = new DataView(encodedGeom, 0, encodedGeom.byteLength);
    var string = decoder.decode(view);
    var geometry = JSON.parse(string);

    return geometry
}

function getSortedFrames(batch) {
    const ordered = Object.keys(batch).sort().reduce(
        (obj, key) => {
            obj[key] = batch[key];
            return obj;
        },
        {}
    );
    var frameArray = []
    Object.keys(ordered).forEach(key => {
        frameArray.push(ordered[key])
    });
    return frameArray
}


function BuildMesh(idx, ref) {

    return (
        <mesh ref={ref} key={idx} castShadow={true} receiveShadow={false} scale={0.007} rotation-y={Math.PI} position={[2, -10, -15]}>

            <bufferGeometry
                attach="geometry"
                onUpdate={self => self.computeVertexNormals()}
            >


            </bufferGeometry>

            <meshBasicMaterial attach='material' side={THREE.FrontSide} color={'yellow'} />

        </mesh>)
}

class ReadArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //
            loaded: false, //whether the first frame has been loaded
            buildingBuffer: true, //whether it's currently accumulating batches to get ahead of user position
            bufferCounter: 3, //how many batches left for accumulation
            //worker data
            readerWorker: null, //web worker for parsing mesh data
            decoderWorkers: [], //web workers for decoding texture data
            onGoingReaderRequests: new Set(),//keep track of ongoing reader worker jobs 
            //buffers
            batchBuffer: {}, //batches currently loaded for playback
            fetchedBatches:{},//batches currently fetched from s3
            framesFromDecoder: {}, //accumulates frames from the batch currently being processed
            //rendering data
            nextFrame: null, //next frame in the frameBuffer          
            currMeshList: [], //meshes to be rendered
            meshRefs: [], //references to the meshes in currMeshList
            meshNum: 0, //number of meshes of recording
            //batch position and request tracking
            playbackBatch: 1, //playback position batch-wise
            processingBatch: 1, //next batch to be sent to ReaderWorker/decoder
            fetchingBatch: 1, //batch currently being fetched
            lastBatchInBuffer: 1, //last batch enqueued for playback (the farthest ahead timeline-wise)
            requestsNum: 0, //number of ongoing S3 batch requests
            //archive data storage info
            lengthInBatches: 0, //recording length in batches
            dracoEncoding: true,
            base64Encoding: true
        };
        this.startGeometryUpdater = this.startGeometryUpdater.bind(this)
        this.fetchNextBatch = this.fetchNextBatch.bind(this)
        this.receivedReaderWorkerMessage = this.receivedReaderWorkerMessage.bind(this)
        this.updateBufferGeometry = this.updateBufferGeometry.bind(this)
        this.sendBatchToWorker = this.sendBatchToWorker.bind(this)
        this.setup = this.setup.bind(this)
        this.processNextBufferedBatch=this.processNextBufferedBatch.bind(this)
        this.createBatchBuffer=this.createBatchBuffer.bind(this)
        this.processTimelineChange=this.processTimelineChange.bind(this)
        this.discardUnneededFrames=this.discardUnneededFrames.bind(this)
    }

    //update each mesh geometry buffer using references
    updateBufferGeometry() {
        var frameToRender = this.state.nextFrame
        for (let i = 0; i < this.state.meshNum; i++) {
            if (frameToRender && frameToRender[i] !== undefined && frameToRender[i].textureData !== undefined && frameToRender[i].geometry!=null) {
                //get mesh and its reference
                var ref = this.state.meshRefs[i]
                var mesh = frameToRender[i]
                //get decoder output texture data 
                var texData = new Uint8Array(frameToRender[i].textureData.buf)
                var height = frameToRender[i].textureData.height
                var width = frameToRender[i].textureData.width
                var vertices, triangles, uvs
                if (this.state.dracoEncoding) {
                    vertices = new Float32Array(Object.values(mesh.geometry.attributes[0].array))
                    uvs = new Float32Array(Object.values(mesh.geometry.attributes[1].array))
                    triangles = new Uint16Array(Object.values(mesh.geometry.index.array))
                } else {
                    vertices = mesh.geometry.vertices
                    uvs = new Float32Array(Object.values(mesh.geometry.uvs))
                    triangles = mesh.geometry.faces
                }
                const texture = new THREE.DataTexture(texData, width, height, THREE.RGBAFormat);
                //create new geometry buffer with the data extracted previously
                var newGeometry = new THREE.BufferGeometry()
                newGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                newGeometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                newGeometry.setIndex(new THREE.BufferAttribute(triangles, 1));
                newGeometry.computeVertexNormals()
                var newMat = new THREE.MeshBasicMaterial()
                newMat.side = THREE.FrontSide
                newMat.map = texture
                //access mesh through reference, replace geometry and texture
                // eslint-disable-next-line
                const geometry = new THREE.PlaneGeometry(1, 1);
                ref.current.geometry = newGeometry
                ref.current.material = newMat

            }
        }
    }

    //set geometry updates to be done every X milliseconds. X is framerate-based.
    startGeometryUpdater() {
        var frameTime = (1 / this.props.playerState.framerate) * 1000
        setInterval(() => {
            //if we haven't reached the end of the recording
            if (this.props.playerState.timelinePos < this.props.playerState.hologramLength - 1) {
                if (this.props.playerState.status !== playStatus.PAUSED) {
                    //if there are frames to be displayed, set the new geometry
                    //console.log(this.state.playbackBatch)
                    if (!this.state.buildingBuffer && this.state.batchBuffer.hasOwnProperty(this.state.playbackBatch)) {
                        //console.log(this.state.batchBuffer)
                        //if we were previously buffering, update the state to playing
                        if (this.props.playerState.status === playStatus.BUFFERING) this.props.updateStatus(playStatus.PLAYING, this.props.setStatus)
                        //console.log(this.state.batchBuffer)
                        if (this.state.batchBuffer[this.state.playbackBatch].length===1) {
                            let nf=this.state.batchBuffer[this.state.playbackBatch].shift()
                            this.setState({
                                nextFrame: nf,
                                playbackBatch: this.state.playbackBatch+1
                            }, this.updateBufferGeometry)
                            delete this.state.batchBuffer[this.state.playbackBatch]
                        }else{
                            this.setState({
                                nextFrame: this.state.batchBuffer[this.state.playbackBatch].shift(),
                            }, this.updateBufferGeometry)
                        }
                        this.props.incrementTimelinePos(this.props.timelinePos, this.props.setTimelinePos)
                        //if we have less than 5 ongoing batches being processed, fetch the next one
                        if (Object.keys(this.state.batchBuffer).length < 3 && this.state.requestsNum < 4 && this.state.fetchingBatch <= this.state.lengthInBatches) {
                            this.setState({ fetchingBatch: this.state.fetchingBatch + 1, requestsNum: this.state.requestsNum + 1 }, this.fetchNextBatch(this.state.fetchingBatch))
                        }
                        //else change state to buffering/loading
                    } else {
                        this.props.updateStatus(playStatus.BUFFERING, this.props.setStatus)
                    }
                }
            } else {
                if (this.props.playerState.status !== playStatus.FINISHED) {
                    this.setState({
                        nextFrame: this.state.batchBuffer[this.state.playbackBatch].shift()
                    }, this.updateBufferGeometry)
                    this.props.incrementTimelinePos(this.props.timelinePos, this.props.setTimelinePos)
                    this.props.updateStatus(playStatus.FINISHED, this.props.setStatus)
                }
            }
        }, frameTime)
    }

    //accumulates batches to get ahead of timeline progression
    createBatchBuffer(newPos) {
        var fb = newPos
        for (let n = newPos; n < newPos + 3; n++, fb++) {
            this.fetchNextBatch(n)
        }
        this.setState({
            requestsNum: 3,
            fetchingBatch: fb
        })
    }

    receivedReaderWorkerMessage(event) {
        if (event.data.type === 'decoderReady') {
            this.createBatchBuffer(1)
        } else
            if (event.data.type === 'Processed Frame') {
                var batchID = event.data.batchID
                //use batch only if it's still valid
                if (this.state.onGoingReaderRequests.has(batchID)) {
                    var batch = this.state.framesFromDecoder
                    var geometry
                    if (this.state.dracoEncoding) {
                        if (event.data.geometry!=null){
                            geometry = getGeometry(event.data.geometry);
                        }else{
                            geometry=null
                        }
                    } else {
                        geometry = event.data.geometry
                    }
                    let textureData = { buf: event.data.textureData, height: event.data.textureHeight, width: event.data.textureWidth }
                    let mesh = { geometry: geometry, textureData: textureData };
                    if (!batch.hasOwnProperty(event.data.batchID)) batch[event.data.batchID] = {};
                    if (!(batch[event.data.batchID]).hasOwnProperty(event.data.frameID)) (batch[event.data.batchID])[event.data.frameID] = {};
                    ((batch[event.data.batchID])[event.data.frameID])[event.data.meshID] = mesh;
                    this.setState({ framesFromDecoder: batch })
                    //console.log(batch)
                    //if there are no jobs left for this batch
                    if (event.data.decodingJobsLeft === 0) {
                        console.log('Finished decoding ' + event.data.batchID)
                        if (this.state.bufferCounter === 1) {
                            this.setState({
                                buildingBuffer: false,
                                bufferCounter: 0
                            })
                        } else {
                            this.setState({
                                bufferCounter: this.state.bufferCounter - 1
                            })
                        }
                        var frameArray = getSortedFrames(batch[event.data.batchID])
                        //only start geometry updater at the beginning
                        if (!this.state.loaded) {
                            var refs = []
                            var baseMeshList = []
                            var meshNum = this.state.meshNum
                            //create build meshes and create references to them
                            for (let i = 0; i < meshNum; i++) {
                                var ref = React.createRef()
                                if (this.props.getRef) {
                                    ref = this.props.getRef
                                }
                                refs.push(ref)
                                if (this.props.setArchiveRef !== undefined && this.props.setArchiveRef !== null) {
                                    this.props.setArchiveRef(ref)
                                }
                                var baseMesh = BuildMesh(i, ref)
                                baseMeshList.push(baseMesh)
                            }
                            var playbackBatchBuf = this.state.batchBuffer
                            playbackBatchBuf[batchID] = frameArray
                            var currFrames = this.state.framesFromDecoder
                            delete currFrames[batchID]
                            this.setState({
                                requestsNum: this.state.requestsNum - 1,
                                framesFromDecoder: currFrames,
                                batchBuffer: playbackBatchBuf,
                                loaded: true,
                                meshRefs: refs,
                                currMeshList: baseMeshList,
                                lastBatchInBuffer: batchID
                            })
                            this.startGeometryUpdater();
                        } else {
                            // eslint-disable-next-line
                            var currFrames=this.state.framesFromDecoder
                            delete currFrames[batchID]
                            // eslint-disable-next-line
                            var playbackBatchBuf=this.state.batchBuffer
                            playbackBatchBuf[batchID]=frameArray
                            this.setState({
                                requestsNum:this.state.requestsNum-1,
                                framesFromDecoder: currFrames,
                                batchBuffer: playbackBatchBuf,
                                lastBatchInBuffer: batchID
                            })
                        }
                        this.state.onGoingReaderRequests.delete(batchID)
                    }
                }
            }
    }

    processNextBufferedBatch() {
        var currFetchedBatches = this.state.fetchedBatches
        if (currFetchedBatches.hasOwnProperty(this.state.processingBatch)) {
            this.sendBatchToWorker(currFetchedBatches[this.state.processingBatch], this.state.processingBatch)
        }
    }

    sendBatchToWorker(batchData, batchID) {
        if (batchID === this.state.processingBatch) {
            var posInSecs = (batchID - 1) * BATCH_SIZE
            var batchStart = 0
            if (posInSecs >= BATCH_SIZE) {
                batchStart = Math.floor(posInSecs / BATCH_SIZE) * (this.props.playerState.framerate * BATCH_SIZE)
            }
            var dataToSend;
            if (this.state.base64Encoding) {
                dataToSend = B64.decode(batchData)
            } else {
                dataToSend = batchData
            }
            var message = {
                type: 'Batch',
                batchID: batchID,
                batch: dataToSend,
                timelinePos: this.props.playerState.timelinePos,
                pos: batchStart
            }
            this.state.readerWorker.postMessage(message)
            this.state.onGoingReaderRequests.add(batchID)
            console.log('Sending batch ' + batchID + ' for decoding')
            delete this.state.fetchedBatches[this.state.processingBatch]
            this.setState({ processingBatch: this.state.processingBatch + 1 }, this.processNextBufferedBatch)

        } else {
            // eslint-disable-next-line
            this.state.fetchedBatches[batchID] = batchData
        }
    }
    
    fetchNextBatch(batchID) {
        console.log('Getting batch ' + batchID)
        const userArchive = this.props.archiveId;
        fetch('https://tz3a1n5npi.execute-api.ap-northeast-1.amazonaws.com/Dev/archiveGet',
            {
                method: 'POST',
                body: JSON.stringify({ archive_name: userArchive, packet_id: batchID })
            })
            .then(res => res.json())
            .then(js => {
                fetch(js.content,
                    {
                        method: 'GET',
                    })
                    .then(data => data.blob())
                    .then(buf => buf.arrayBuffer())
                    .then(bin => {
                        console.log('Received batch ' + batchID + ' from S3')
                        var decoder = new TextDecoder("utf-8");
                        this.sendBatchToWorker(decoder.decode(bin), batchID)
                    })
            })
    }



    setup(err, data) {
        if (!err) {
            // console.log(data)
            //get hologram info
            var hologramLength = data.Item.LengthInFrames
            var draco = data.Item.DracoEncoding
            var base64 = data.Item.base64;
            var framerate = data.Item.Framerate
            var lengthInBatches = data.Item.BatchNum
            var meshNum = data.Item.MeshNum
            //worker initialization
            //reader worker
            // var readerWorker = new Worker(process.env.PUBLIC_URL + "./ReaderWorker.js", { type: "module" })
            var readerWorker = new Worker(process.env.PUBLIC_URL + "./ReaderWorker.js", { type: "module" })
            // var readerWorker = new Worker(ReaderWorker, { type: "module" })
            readerWorker.onmessage = this.receivedReaderWorkerMessage
            var message = {
                type: 'Setup',
                dracoEncoding: draco,
                url: process.env.PUBLIC_URL,
                workerNum: meshNum
            }
            readerWorker.postMessage(message)
            this.setState({
                meshNum: meshNum,
                lengthInBatches: lengthInBatches,
                readerWorker: readerWorker,
                dracoEncoding: draco,
                base64Encoding: base64
            })
            this.props.setMetadata(hologramLength, framerate, this.props.setHologramLength, this.props.setFramerate)
        } else {
            console.log(err)
        }
    }

    discardUnneededFrames() {
        var currBuf = this.state.batchBuffer
        var currKeys = Object.keys(currBuf)
        for (let i = 0; i < currKeys.length; i++) {
            let batchID = currKeys[i]
            if (batchID < this.state.playbackBatch) {
                delete currBuf[batchID]
            } else {
                for (let f = 0; f < currBuf[batchID].length; f++) {
                    if (batchID * BATCH_SIZE + f < this.props.timelinePos) {
                        currBuf[batchID].shift()
                    }
                }
                break
            }
        }
        this.setState({
            buildingBuffer: false
        })
    }

    processTimelineChange() {
        if (this.state.playbackBatch > this.state.lastBatchInBuffer) {
            //invalidate all previous requests
            this.state.onGoingReaderRequests.clear()
            this.setState({
                processingBatch: this.state.playbackBatch,
                fetchingBatch: this.state.playbackBatch,
                batchBuffer: {},
                framesFromDecoder: {},
                fetchedBatches: {},
                buildingBuffer: true,
                bufferCounter: 3
            }, this.createBatchBuffer(this.state.playbackBatch))
        } else {
            if (this.state.lastBatchInBuffer - this.state.playbackBatch < 2) {
                this.setState({
                    fetchingBatch: this.state.playbackBatch + 1,
                    processingBatch: this.state.playbackBatch + 1,
                    buildingBuffer: true,
                    bufferCounter: 3
                }, (function () {
                    this.discardUnneededFrames()
                    this.createBatchBuffer(this.state.playbackBatch)
                }))
            } else {
                this.setState({
                    fetchingBatch: this.state.playbackBatch + 1,
                    processingBatch: this.state.playbackBatch + 1,
                    buildingBuffer: true
                }, (function () {
                    this.discardUnneededFrames()
                }))
            }
        }
    }

    componentDidMount() {
        console.log("Holotch archive web player version 1.0")
        const params = {
            TableName: 'StoredHolograms',
            Key:
            {
                HologramID: this.props.archiveId
            }
        }

        dynamodb.get(params, this.setup)
    }

    componentDidUpdate(prevProps) {
        //if the user changed timeline position
        if (Math.abs(this.props.playerState.timelinePos - prevProps.playerState.timelinePos) > 1) {
            var newPos = Math.floor((this.props.playerState.timelinePos / this.props.playerState.framerate) / BATCH_SIZE) + 1
            console.log(newPos)
            if (newPos < this.state.playbackBatch) {
                //invalidate all previous requests
                this.state.onGoingReaderRequests.clear()
                this.setState({
                    playbackBatch: newPos,
                    processingBatch: newPos,
                    fetchingBatch: newPos,
                    batchBuffer: {},
                    framesFromDecoder: {},
                    fetchedBatches: {},
                    buildingBuffer: true,
                    bufferCounter: 3
                }, this.createBatchBuffer(newPos))
            } else {
                this.setState({ playbackBatch: newPos }, this.processTimelineChange)
            }
            console.log('Timeline changed to: ' + this.props.playerState.timelinePos)
        }
        if (prevProps.timelinePos !== this.props.timelinePos) {
            if (this.state.loaded) {
                this.props.pageLoaderCheck(this.state.loaded, this.props.setCheckLoader)
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    //return null;
    render() {
        if (this.state.loaded) {
            return this.state.currMeshList
        } else {
            return null
        }
    }
};

export default ReadArchive