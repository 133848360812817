import React from 'react';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { ControlBtnOverlay } from '../hologramui.style';
// import { RoundedIconBtn } from '../../../pages/hologramUi/hologramui.style';

// import ImageLoader from '../../../components/pageloaders/ImageLoader';

function SectionThree({
    captureBtn,
    toggleArVr,
    capturePhoto,
    startRecording,
    recoredActive,
    viewerModeProps,
    refEelement,
    setRecoredActive,
    setCapturedPhoto,
    setCameraCapture,
    secondsToMinutes,
    setVideoRecTimer,
    setRecTimerSec,
    iosViewer,
    handleShare,
    archiveId,
    iosExpand,
    liveStreamDemo,
    is_stream
}) {
    const {
        // screenMargin,
        photoMode,
        // videoMode,
    } = viewerModeProps
    // console.log(refEelement)
    const arViewBtnStyle = {
        background: 'linear-gradient(180deg, #AB6DFA 0%, #6603E3 86.61%)',
        border: '1px solid rgba(255, 255, 255, 0.87)',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)'
    }
    const vrViewBtnStyle = {
        border: 'none',
        background: 'none',
    }

    return (
        <>
            <ControlBtnOverlay>
                <MDBContainer className='d-block col-lg-4 col-md-12'>
                    <MDBRow>
                        <MDBCol className='col-8 col-md-7 col-lg-12 ms-auto'>
                            <MDBRow>
                                <MDBCol className='col-8 mx-auto'>
                                    {!iosViewer ?
                                        <button
                                            style={vrViewBtnStyle}
                                            onClick={photoMode ? () => startRecording(refEelement, setRecoredActive, recoredActive, setVideoRecTimer, setRecTimerSec) : () => capturePhoto(setCapturedPhoto, setCameraCapture)}
                                            className={`btn btn 
                                        ${recoredActive ? 'far fa-stop-circle' : 'bi bi-circle-fill'} 
                                        
                                                text-${captureBtn.bg} record_btn_capture`}
                                        ></button>
                                        : <></>}
                                    {/* <button style={vrViewBtnStyle}
                                        onClick={photoMode ? () => startRecording(refEelement, setRecoredActive, recoredActive) : () => capturePhoto(setCapturedPhoto, setCameraCapture)}
                                    >
                                        <button
                                            style={vrViewBtnStyle}
                                            onClick={photoMode ? () => startRecording(refEelement, setRecoredActive, recoredActive) : () => capturePhoto(setCapturedPhoto, setCameraCapture)}
                                            className={`btn btn 
                                                ${recoredActive ? 'bi bi-stop-circle text-danger record_btn' : 'bi bi-record-fill'} 
                                                text-${captureBtn.bg} 
                                                capture_btn text-center`}
                                        ></button> */}
                                    {/* {!recoredActive ?
                                            <button
                                                className={`btn btn 
                                                ${recoredActive ? 'bi bi-stop-circle text-danger record_btn' : 'bi bi-record-fill'} 
                                                text-${captureBtn.bg} 
                                                capture_btn text-center`}
                                            ></button>
                                            // <i
                                            //     className={`btn-${captureBtn.bg}  bi bi-record-fill bg-${captureBtn.bg} border-0 |${captureBtn.bd} `}>
                                            // </i>
                                            :
                                            <i className="bi bi-stop-circle text-danger record_btn"></i>
                                            // <i
                                            //     className={`btn-outline-white iconBtnStyle bg-none ${captureBtn.bg} border-${captureBtn.bd}  rounded-circle`}
                                            //     >
                                            //     <i className="bi bi-stop-circle text-danger"></i>
                                            // </i>
                                        } */}
                                    {/* </button> */}
                                </MDBCol>
                                <MDBCol className='col-4 col-md-2 me-auto mt-2 d-lg-none'>
                                    {!iosViewer ?
                                        <Link to='/ar'>
                                            <button className="rounded-circle p-2" style={arViewBtnStyle}>
                                                <img src={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/view_in_ar_white_24dp+1%402x.png'}
                                                    alt="" style={{ width: '35px', height: '35px' }} />
                                            </button>
                                        </Link>
                                        :
                                        <>
                                            {iosExpand ?
                                                <>
                                                    {liveStreamDemo ?
                                                        <Link to={is_stream ? '/xr-stream' : 'xr-viewer'}>
                                                            <button className="rounded-circle p-2" style={arViewBtnStyle}>
                                                                <img src={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons-white/view_in_ar_white_24dp+1%402x.png'}
                                                                    alt="" style={{ width: '35px', height: '35px' }} />
                                                            </button>
                                                        </Link>
                                                        :
                                                        <div className="">
                                                        <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Icons/Custom/Share%403x.png"
                                                          alt=""
                                                          className=""
                                                          style={{ width: '40px', height: '40px', marginRight: '-10px', background: 'rgba(18, 18, 18, 0.5)', borderRadius: '50%', cursor: 'pointer' }}
                                                          onClick={() => handleShare(window.location.href)}
                                                        />
                                                      </div>
                                                        // <RoundedIconBtn icon='fa fa-share  text-white fs-5 d-block d-lg-none'
                                                        //     action={() => handleShare(`https://development.d38goyqvv5m6x.amplifyapp.com/viewer?archive_id=${archiveId}`)}
                                                        // />
                                                    }
                                                </>
                                                :
                                                <></>}
                                        </>}
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </ControlBtnOverlay>

        </>
    )
}

export default SectionThree
