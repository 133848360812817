import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { DetailBody } from './detailSection.style';


export const CreatorSection = () => {    
    return (
        <>
            <MDBRow className='row mt-3 mb-2'>
                <MDBCol className='col-8'>
                    <MDBRow>
                        <MDBCol className='col-3'>
                            {/* <UserInitialz bgcolor={'#4C43B9'} initial={'V'} /> */}
                            <img src="https://res.cloudinary.com/dzql5s4vq/image/upload/v1631117957/Holotch/hotch_peht6r.png"
                                alt="" className="rounded-circle" width="50" height="50" />
                        </MDBCol>
                        <MDBCol className='col-7 mx-auto text-center'>
                            <span className="">Holotch</span>
                            <br />
                            {/* <span className="text-muted small">hologram</span> */}
                        </MDBCol>
                        <MDBCol className='col-2 text-start'>
                            <p className='text-white bg-danger badge'>LIVE STREAM</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </>
    )
}
export const DetailPost = () => {
    return (
        <>
            <MDBRow>
                <MDBCol className='col-12'>
                    <DetailBody className='card text-dark text-center'>
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. */}
                        <h3>{'Holotch'}</h3>
                    </DetailBody>
                </MDBCol>
            </MDBRow>
        </>
    )
}

function ViewerSidebar() {
    const [getDate, setGetdate] = React.useState(new Date());
    const getToday=()=>{
        const today = new Date();
        var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
        setGetdate(date);
    }
    React.useEffect(()=>{
        getToday();
    }       ,[getDate]);
    return (
        <MDBContainer className='text-white'>
            <div className='border-bottom border-muted py-3 mt-3'>
                <div className=''>
                    <p className='h5 fw-normal'>Holotch</p>
                    <span className='small'>Hologram Live Streaming Demo</span> <br />
                    <span className='text-muted small'>• {String(getDate)}</span>
                </div>
            </div>
            <CreatorSection />
            <DetailPost />

        </MDBContainer>
    )
}

export default ViewerSidebar;
