

export const GET_ERRORS = "GET_ERRORS";

export const returnErrors = (msg, status) => {
    return {
        type: GET_ERRORS,
        payload: { msg, status },
    };
};


// R E D U C E R
const initialState = {
    msg: false,
    errors: false,
    status: null,
};


export default function alert(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                msg: action.payload.msg,
                errors: action.payload.msg.errors,
                status: action.payload.status,
            };
        default:
            return state;
    }
}
